/**
 * Adds search params from window.location to Pardot iframe.src
 * @version 1.0.0
 * @author Erik Ondrus <erik.ondrus@avast.com>
 * @requires elements with class: .utm-iframe
 */

(function () {
	'use strict';

	var IFRAME_SELECTOR = '.utm-iframe';

	var iframes = document.querySelectorAll(IFRAME_SELECTOR);

	iframes.forEach(function (iframe) {
		var iframeUrl = new URL(iframe.src);
		var locationParams = new URLSearchParams(window.location.search);

		var iframeEntries = Array.from(iframeUrl.searchParams.entries());
		var locationEntries = Array.from(locationParams.entries());

		var newEntries = iframeEntries.concat(locationEntries);

		var newIframeParams = new URLSearchParams(newEntries).toString();

		var newIframeUrl = new URL(
			iframeUrl.origin + iframeUrl.pathname + '?' + newIframeParams
		);

		iframe.src = newIframeUrl.href;
	});
})();
